
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.ttf') format('truetype');
}

#root { 
    position: absolute;
    width: 100%;
}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Bold';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Bold.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Book';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Book.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Medium';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Medium.ttf') format('truetype');

}



@font-face {

    font-family: 'icomoon';

    src: url('../fonts/icomoon.eot') format('embedded-opentype');

}

@font-face {

    font-family: 'icons';

    src: url("../fonts/icomoon.eot?ym9sg2");

    src: url("../fonts/icomoon.eot?ym9sg2#iefix") format("embedded-opentype"), url("../fonts/icomoon.svg?ym9sg2#icomoon") format("svg"), url("../fonts/icomoon.woff2?ym9sg2") format("woff2"), url("../fonts/icomoon.ttf?ym9sg2") format("truetype"), url("../fonts/icomoon.woff?ym9sg2") format("woff");

    font-weight: normal;

    font-style: normal

}

body {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 400;

    color: #333;

}



.header_bg {

    background-image: url('./images/header-img.jpg');

    background-repeat: no-repeat;

}

/* DEMO-SPECIFIC STYLES */

.typewriter h1 {

    border-right: .15em solid orange; /* The typwriter cursor */

    margin: 0 auto; /* Gives that scrolling effect as the typing happens */

    letter-spacing: .15em; /* Adjust as needed */

    animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;

}

.dis-block {
    display: block;
}

.team-section .accordion-button {
    color: #ffffff;
    background-color: #000;
   
}
    .team-section .accordion-button:not(.collapsed) {
        color: #fff !important;
        background-color: #86c442 !important;
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    }
        .team-section .accordion-button:not(.collapsed)::after {
            filter: brightness(0) invert(1);
        }
    .team-section .accordion-button::after {
        filter: brightness(0) invert(1);
    }
/* The typing effect */

@keyframes typing {

    from {

        width: 0

    }



    to {

        width: 100%

    }

}



/* The typewriter cursor effect */

@keyframes blink-caret {

    from, to {

        border-color: transparent

    }



    50% {

        border-color: orange

    }

}

.plus_icon {

    font-size: 2em !important;

    vertical-align: super;

}

.search {
    position: relative;
    box-shadow: 0 0 40px rgb(51 51 51 / 10%);
    float: left;
    width: 45%;
  top: 20px;
}

.search input {
    height: 45px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
}

img {
    width: 100%;
    max-width: 100%;
}
.modal-header .close {
padding: 0rem 1rem;
    margin: -1rem -1rem -1rem auto;
    border: 0px;
    background: none;
}
.close {
     float: right;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    color: #8f8f8f;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
}
.cookies-list {
    position: fixed;
    bottom: 0px;
    padding: 10px;
}
.article-thumbnail {
    margin-right: 16px;
}

.article-thumbnail {
    padding: 8px;
    border: 1px solid #ccc;
    background: #f5f5f5;
    margin: 0 auto 10px;
    width: 100%;
    display: block;
}
    .article-thumbnail img {
        display: block;
        max-width: inherit;
        width: inherit;
    }
    .entry-title {
    font-size: 26px;
    margin: 0;
}
        .entry-title a {
            color: #000 !important;
        }
a.more-link {
    color: #000 !important;
}
.single-article a { text-decoration:none;
                  color:inherit;
                  border:0px;
}
.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
}

.article-header .posted-on {
    display: block;
}
.dates {
    background: #000000;
    padding: 10px 20px;
    margin-right: 2px;
    border: 0px;
    color: #fff !important;
    text-decoration: none;
}

.act-date {
    background-color: #86c442;
    color: #fff;
}

.posted-on, .taxonomy-description {
    font-weight: 700;
    font-size: 11px;
    color: #333;
}
    .posted-on a {
        color: inherit;
    }
.sp-template {
    margin: 0;
    padding: 0;
    border: none;
    background: #f5f5f5;
}

.sp-template {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    padding: 1px;
    background: #fff;
    clear: both;
    margin-top: 15px;
}
    .sp-table-caption {
        background: #d4000f;
        color: #fff;
        font-size: 12px;
        padding: 6px 8px;
        margin: 0 0 1px;
        font-weight: 700;
        line-height: 1;
        box-shadow: inset 0 -12px 24px -10px rgb(0 0 0 / 25%);
    }
.sp-template table:last-child {
    margin-bottom: 0;
}

.sp-template table {
    font-size: 12px;
    width: 100%;
}
.sp-template table th {
    padding: 6px 8px;
    line-height: 1;
    background: #fff;
    font-weight: 700;
}
tbody tr td {
    border-bottom: 1px solid #fff;
}

.sp-template table td {
    padding: 8px;
    background: #f5f5f5;
}
.d4000f {
    color: #000 !important;
    text-decoration: none;
}

.data-name img {
    width: auto !important;
    max-width: initial !important;
}
.SizeGuide {
    text-align: center;
    font-weight: 700;
}
.SizeGuide__title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    height: 32px;
}
.SizeGuide__scroll-container {
    overflow: auto;
}
.SizeGuide table, .SizeGuide td, .SizeGuide th {
    border: 1px solid #000;
    border-collapse: collapse;
}
.SizeGuide tr {
    background: #fafafa;
}
.SizeGuide td, .SizeGuide th {
    padding: 10px;
    white-space: nowrap;
}
.SizeGuide table, .SizeGuide td, .SizeGuide th {
    border: 1px solid #000;
    border-collapse: collapse;
}
.team_section { 
    margin-bottom: 8px;
    border: 1px solid #ccc;
    padding: 1px;
    background: #fff;
    clear: both;
 
}
 .player-group-name {
    background: #d4000f;
    color: #fff;
    font-size: 12px;
    padding: 6px 8px;
    margin: 0 0 1px;
    font-weight: 700;
    line-height: 1;
    box-shadow: inset 0 -12px 24px -10px rgb(0 0 0 / 25%);
}
.team_inner { padding:0px;
}
.has-number {
    padding: 4px;
    clear: both;
    background: #f5f5f5;
    border: 1px solid #ccc;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    line-height: 16px;
    color: #666;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
}

.has-number a {
   text-decoration:none;
}
.team_section a {
    text-decoration: none;
}
.desktop_view {
    display: block;
}


.mobile_view{ display:none; }


.ProductOptions {
    margin-bottom: 15px;
}

/* Choose all input elements that have the attribute: type="radio" and make them disappear.*/
.ProductOptions  input[type="radio"] {
  display: none;
}

/* The label is what's left to style. 
As long as its 'for' attribute matches the input's 'id', it will maintain the function of a radio button. */
.ProductOptions  label {
   padding: 0.6em;
    display: inline-block;
    border: 1px solid #8cc847;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    width: 45px;
    height: 45px;
    color: #8cc847;    margin-right: 20px;
}

.ProductOptions  .blank-label {
  display: none;
}

/* The '+' is the adjacent sibling selector.
It selects what ever element comes right after it,
as long as it is a sibling. */
.ProductOptions  input[type="radio"]:checked + label {
  background: #8cc847;
  color: #fff;
}

.RoundRadioButton--sold-out {
    opacity: .5;
    pointer-events: none;
}



 
.ProductOptions .Radio.RoundRadioButton {
    margin-right: 13px;
    margin-bottom: 10px;
}
 

a.ProductOptions__size-guide {
    text-decoration: none;
    border: 0px;
    color: black;
}

.product-widget {
    border: 1px solid #eaeaea;
    margin-bottom: 30px;
}
.product-widget a, .widget-content h4, .product-widget {
    transition: all 0.4s ease-in-out;
}
.product-img {
    text-align: center;
}

.otherProducts img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
}

.widget-content {
    padding: 25px 0;
    color: #173242;
    text-align: center;
}
.mr5{ margin-right:5px ! important; }



.otherProducts .widget-content>h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.widget-content p {
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 18px;
    color: #8c8c8c;
}

.otherProducts h5 {
    font-size: 16px;
}

.product-widget a, .widget-content h4, .product-widget {
    transition: all 0.4s ease-in-out;
}
.widget-content h4 {
    color: #000;
    font-size: 19px;
}


.right_arrow{    width: 1.3em;
    height: 1.3em;
    margin-left: 10px;}



























































































































































































































































































 .Opta-Tabs ul.Opta-Cf li.Opta-On {
    color: #75263b;
    margin: 0 auto;
}

  .Opta-Tabs ul.Opta-Cf li:nth-child(1):before {
    content: '';
}

  .Opta-Tabs ul.Opta-Cf li:before {
    cursor: pointer;
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto 0;
    width: 49px;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-family: "Gibbs-Book",Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 34px;
    text-align: center;
}

.Opta-Tabs ul.Opta-Cf li {
    background-repeat: no-repeat;
    background-position: right center;
    padding: 40px 0 0px;
    position: relative;
    width: 33.3333%;
    -webkit-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out;
}

 .Opta-Tabs ul.Opta-Cf li:nth-child(2):before {
        content: '';
    }

  .Opta-Tabs ul.Opta-Cf li:nth-child(3):before {
    content: '';
}
.Opta-Tabs ul.Opta-Cf li a {
    background: none;
    color: #333;
    display: block;
    font-family: "Gibbs-Book",Helvetica,Arial,sans-serif;
    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;
    font-weight: 800;
    font-size: 14px;
    padding: 0 6px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.profile_table {
    line-height: 30px;
}



.player-section .tab-content {
    padding-top: 0px;
}

span.fixture {
    font-weight: bold;
    margin-top: 10px;
    display: block;

}
/*.red_color { color:red;
}*/

.width100 { width:100%;
}















.club-profile {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 20px;
    height: 200px;
    border: 1px solid #ccc;
}































.fixtures_table {
    background: #f0f0f0;
    text-align: center;
    font-size: 20px;
}

 .homepage-hero {
    margin-top: 65px;
}


.inner-banner-header {
    padding: 60px 0 0;
    min-height: 200px;
}
.inner-banner-header {
    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/breadcumb.jpg);
text-align: center;
    padding: 60px 0 0;
    position: relative;
    min-height: 200px;
    overflow: hidden;
}
.wf100 {
    width: 100%;
    float: left;
}
.inner-banner-header h1 {
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    position: relative;
}
.inner-banner-header p {
    color: #fff; 
    position: relative;
}
.inner-banner-header h1:after {
    content: attr(data-generated);
    position: absolute;
    left: 0;
    right: 0;
    font-size: 150px;
    top: -68px;
    font-weight: 300;
    color: rgba(255,255,255,.07);
}
.gt-breadcrumbs {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    width: 100%;
}
.gt-breadcrumbs ul {
    margin: 0px;
    padding: 0 50px;
    list-style: none;
    width: auto;
    background: rgba(255,255,255,.20);
    display: inline-block;
    border-radius: 75px 75px 0 0;
}

.fixtures_table tr{
    border-bottom: 1px solid #000;
}
.fixtures_table img {
   width:90px;
}
.zoom {
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
}
.zoom img {
    transition: opacity .5s;
    display: block;
    width: 100%;
}

.zoom img:hover {
    opacity: 0;
}
 
.quantity .number{
		display: contents;
		}
		
	.quantity .minus, .plus{
	width: 30px;
    height: 30px;
    background: #000;
    border-radius: 4px;
    padding: 0px 5px 0px 5px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    cursor: pointer;border-radius: 0px;
		}
	.quantity 	input{
			height:34px;
      width: 70px;
      text-align: center;
      font-size: 26px;
			border:1px solid #ddd;
			border-radius:4px;border-radius: 0px;
      display: inline-block;
	vertical-align: middle;}
	
	





















.date { 
        display:block;
}































.number-play {
    background: #c9000e;
    color: #fff;
    padding: 0px 10px;
    margin-bottom: 20px;
    margin-top: -25px;
    position: absolute;
}







i.comingsoon{
    line-height: 16px;
    white-space: nowrap;
    color: #0b1320;
    border: 1px solid #0b1320;
    border-radius: 3px;
    background: 0 0;
    font-size: 8px;
    vertical-align: text-top;
    font-style: inherit;
    padding: 1px;
}




h3.skills {
    text-align: center;
    color: #333;
}


.player_name {
    background: #eee;
    margin-top: 0px;
    padding: 3px 10px;
    border: 1px solid #ccc;
    font-weight: bold;
}


.team {
    background: #000;
    color: #fff;
    padding: 10px;
}
.fixtures_table .red_color {
    display: block;
    margin-bottom: 10px;
}

.fixtures {
    text-align: left;
    background: #000;
    color: #fff;
    padding: 10px;
}

.profile_bg {
    background: #f5f5f5;
    padding: 10px;
}
.player_txt {
    background: #e9e9e9;
    padding:10px;

}
.search input:focus {
    box-shadow: none;
    border: 2px solid blue
}

.search .fa-search {
    position: absolute;
    top: 15px;
    left: 16px;
	color:#a5a5a5;
}

.search button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue
}
.header {

    margin-top: 20px;

    float: right;

}





    .header .dropbtn {

        background-color: #000;

        color: white;

        padding: 10px 10px;

        font-size: 14px;

        border: none;

    }



    .header .dropdown {

        position: relative;

        display: inline-block;

        margin-left: 10px;

    }



.header .dropdown-content {

    display: none;

    position: absolute;

    background-color: #f1f1f1;

    min-width: 120px;

    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    z-index: 1;

}



    .header .dropdown-content a {

        color: black;

        padding: 12px 16px;

        text-decoration: none;

        display: block;

        border-bottom: 1px solid #86c442;

    

    }



        .header .dropdown-content a:hover {

            background-color: #e9ecef;

        }



.header .dropdown:hover .dropdown-content {

    display: block;

}



    .header .dropdown:hover .dropbtn {

        background-color: #86c442;

    }

.logo img {

    width: 135px;

    padding-top: 5px;

}

.site-header {

    position: relative;

    z-index: 5000;

}

    .site-header > div {

        background-color: #75263b;

        color: #fff;

        background-repeat: no-repeat;

        background-size: contain;

        background-position: top left;

        

        z-index: 999;

        top: 0;

        transition: height 0 ease;

        width: 100%;

    }

.site-header .overlay {

    background-color: #75263b;

    position: absolute;

    top: 0;

    right: 0;

    bottom: 0;

    left: 0;

    opacity: .15;

    display: none;

    z-index: 101;

}



.site-header .part.first {

    padding: 20px 0 22px 0;

}



    .site-header .vertical-center {

        display: table;

        height: 90px;

    }

    .site-header .part.first .club-badge {

        width: 90px;

        height: 90px;

        max-width: 100%;

        transition: width 0 ease,height 0 ease;

    }

.club-badge a {

    display: block;

    width: 100%;

    height: 100%;

    border: none;

}

.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}





.site-header .part.first h1 {

    margin: 0;

    font-size: 24px;

    font-size: 1.33333em;

    letter-spacing: .05em;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

    padding-left: 20px;

}

.site-header .vertical-center > * {

    display: table-cell;

    vertical-align: middle;

}



.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo span {

        display: block;

        text-transform: uppercase;

        padding-bottom: 6px;

    }

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }

.site-header .part.first .club-badge {

    width: 90px;

    height: 90px;

    max-width: 100%;

    transition: width 0 ease,height 0 ease;

}

.club-badge {

    display: block;

    margin: 0 auto;

    width: 90px;

    height: 90px;

    background-size: contain;

    background-repeat: no-repeat;

    background-position: center center;

    background-image: url('./images/abfc.png');

}



.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.vertical-center  h1 {

    font-size: 20px !important;

    font-size: 1.25em;

}



.site-header .part.first .partners .partner-logo img {

    display: block;

    margin: 0 auto;

    max-height: 60px;

    max-width: 100px;

}

.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }



.site-header .part.second {

    background-color: #fff;

    border-bottom: 1px solid #ccc;

    position: absolute;

    width: 100%;

    z-index: 104;

    margin-top: 0;

    transition: top 0 ease,margin-left 0 ease;

}



    .site-header .part.second ul {

        white-space: nowrap;

        list-style: none;

        padding-left: 0;

        margin: 0;

    }

    .site-header .part.second nav li.home-mobile-nav-link {

        display: none;

    }

    .site-header .part.second nav li a {

        display: inline-block;

        text-decoration: none;

        padding: 14px 10px 8px;

        border-bottom: 5px solid transparent;

        color: #333;

        font-size: 16px;

        font-size: .88889em;

        letter-spacing: .05em;

        font-family:  Typetanic-Fonts-Gibbs-Medium;

        font-weight: 800;

    }







.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



label {

    display: inline-block;

    max-width: 100%;

    margin-bottom: 5px;

    font-weight: bold;

}




.site-header .search .buttons {

    float: right;

}

    .site-header .search .buttons .btn.close {

        width: 30px;

        margin-right: 10px;

    }



.site-header .icon-Menu, .site-header .icon-Close, .site-header .icon-Search {

    color: #333;

}



[class^="link-icon-"]:before, [class*=" link-icon-"]:before, [class^="icon-"], [class*=" icon-"] {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.site-header .search .buttons .btn {

    width: 16px;

    height: 30px;

    padding: 0;

    margin-bottom: 0;

    font-size: .89em;

    line-height: 1em;

}



.site-header .search:not(.active) .icon-Search {

    margin-left: -1px;

    opacity: .5;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}

.site-header .part.second .last {

    margin-right: 0px;

    padding-top: 20px;

}



.pull-right {

    float: right !important;

}

.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li {

    display: inline-block;

}

.news-section .row:nth-child(odd) {
    background: #e0e0e0;
    padding: 10px;
    margin-bottom: 20px;
}
.news-section .row {
    margin-bottom: 20px;
    display: flex;
}
.widget-area {
    border: 1px solid #ccc;
    width: 100%;
    padding: 0px 0px 0px;
    margin-left: -1px;
}
.sp-widget-align-none {
    overflow-y: auto;
    height: 250px;
    overflow-x: hidden;
}
.widget .sp-template {
    margin: 0;
    padding: 0;
    border: none;
    background: #f5f5f5;
}

.sp-template {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    padding: 1px;
    background: #fff;
    clear: both;
}
    .sp-table-caption, .sp-template .player-group-name {
        background: #000;
        color: #fff;
        font-size: 12px;
        padding: 6px 8px;
        margin: 0 0 1px;
        font-weight: 200;
        line-height: 1;
        box-shadow: inset 0 -12px 24px -10px rgb(0 0 0 / 25%);
    }
 
.sp-calendar tbody td#today, .sp-data-table tbody tr.alternate td, .sp-data-table tbody tr.odd td {
    background: #f0f0f0;
}
.sp-data-table tbody tr.odd {
    background: initial;
}
.sp-data-table a {
    color: inherit;
    text-decoration:none;
}
.mega-slider__row__thumbnail {
    display: block;
    width: 50px;
    height: auto;
    max-width: 100%;
    float: left;
    margin-right: 10px;
}
.mega-slider__row {
    padding: 10px;
    clear: both;
    overflow: hidden;
    background: #f5f5f5;
    border-bottom: 1px solid rgba(0,0,0,.1);
    cursor: pointer;
    box-sizing: border-box;
}
.mega-slider__row__title {
    display: block;
    margin: 4px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mega-slider__row__link {
    color: inherit;
    float: right;
    margin-left: 10px;
}

.mega-slider__row__link, a, ins, mark {
    text-decoration: none;
}
.grandTotalTable {
    font-size: 16px;
}

    .grandTotalTable td {
        border: 1px solid #000 !important;
    }
.checkout-section td {
    padding: 8px;
    background: #f5f5f5 !important;
}
.site-header .part.second .last .search-link {

    width: 30px;

    height: 30px;

    padding: 1px 0 0 1px;

    font-size: 16px;

    font-size: .88889em;

    margin-bottom: 0;

}

    .site-header .part.second .last .search-link .text {

        display: none;

    }



.site-header > div.headerbg-center {

    background-position: top center;

    background-size: contain;

}

.container-fluid:after {

    clear: both;

}



.container-fluid:before, .container-fluid:after {

    content: " ";

    display: table;

}





.site-header .part.second nav li.prioritynavigation-menu {

    border-left: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li a span.icon-Down-Arrow, .site-header .part.second nav li a span.icon-Up-Arrow {

    font-size: 12px;

    font-size: .66667em;

    display: inline-block;

    min-width: 1em;

}

.icon-Down-Arrow:before {

    content: "";

}

.site-header .on-open {

    display: none;

}

.site-header .on-closed {

    display: block;

}

/*.site-header .part.second nav li .dropdown-menu {

    display: none;

    padding: 3px 0;

}*/

.main_head {

    margin-top: 2em;

    background: #fff;

    padding: 30px 0px;

}

.site-header .part.second nav li ul {

    position: absolute;

    background: #ffffff;

    color: #333;

    padding: 0px 0;

    z-index: 100;

}



.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li:not(:last-child) {

    border-bottom: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li a {

    padding-top: 10px;

    padding-bottom: 10px;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

}

.site-header .part.second nav li .dropdown-menu.open {

    display: block;

}

.site-header .part.second nav li ul li.duplicated-link {

    display: none;

    visibility: hidden;

}

.site-header .part.second nav li ul li {

    display: block;

    border-bottom: 1px solid #86c442;

}









.site-header .part.first .partners {

    float: right;

}

.news-grid {

    background: #000;

    position: relative;

    overflow: hidden; margin-bottom: 40px;

}

.news-grid-article {

    width: 100%;

    height: 100%;

    position: relative;

    display: block;

    border: none;

}

    .news-grid-article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }



    .news-grid-article .overlay {

        background: -moz-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: -webkit-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#000000',GradientType=0);

        width: 100%;

        position: absolute;

        top: 0;

        bottom: 0;

        z-index: 10;

    }

    .news-grid-article .inner {

        padding: 40px;

    }



    .news-grid-article .inner {

        position: absolute;

        z-index: 100;

        bottom: 0;

        padding: 20px;

        color: #fff;

    }

    .news-grid-article .detail-block .news-detail-wrap, .news-grid-article .detail-block .block-inner {

        max-width: 1000px;

        overflow: hidden;

    }

    .news-grid-article .detail-block span {

        display: block;

    }

    .news-grid-article .detail-block h2 {

        margin-bottom: 0;

        border-bottom: 1px solid transparent;

        display: inline;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .news-grid-article .detail-block span.date {

        margin-top: 5px;

    }



    .news-grid-article .image-container img {

        width: 100%;

        -moz-transition: all 1s ease-out;

        -webkit-transition: all 1s ease-out;

        transition: all 1s ease-out;

    }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.column-collapse .news-grid-article .detail-block h2 {

    font-size: 18px;

    font-size: 1em;

}

h2, .h2 {

    font-weight: 600;

}

.site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

    position: fixed;

    top: 0;

    left: 0;

    right: 0;

    border-top: none;

    height: 50px;

}



.site-header.scroll-lock:not(.navigation-active), .site-header.match-centre:not(.navigation-active) {

    height: 140px;

}

    .site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

        position: fixed;

        top: 0;

        left: 0;

        right: 0;

        border-top: none;

        height: 50px;

    }

    .site-header.scroll-lock:not(.navigation-active) > div, .site-header.match-centre:not(.navigation-active) > div {

        height: 50px;

    }





.sticky {

    position: fixed !important;

    top: 0;

    width: 100%;

}



    .sticky .site-header > div.headerbg-center {

        margin-top: -133px;

    }

nav.navbar.navbar-expand-lg.navbar-light {

    padding: 20px 0px;

}

    .sticky + .content {

        padding-top: 102px;

    }



.nav-tabs > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

}



#exTab1 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



#exTab2 h3 {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



/* remove border radius for the tab */



#exTab1 .nav-pills > li > a {

    border-radius: 0;

}



/* change border radius for the tab , apply corners on top*/



#exTab3 .nav-pills > li > a {

    border-radius: 4px 4px 0 0;

}







.nav-tabs {

    border-bottom: 1px solid #ddd;

}

    .nav-tabs > li {

        float: left;

        margin-bottom: -1px;

    }

        .nav-tabs > li > a {

            margin-right: 2px;

            line-height: 1.42857143;

            border: 1px solid transparent;

            border-radius: 4px 4px 0 0;

        }



.nav > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

    color: #333;

    text-decoration: none;

    border-bottom: none;

}

   

    .nav > li > a.active {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .nav > li > a:hover {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

.tab-content {

    padding-top: 30px;

}

#exTab3 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}





.article {

    position: relative;

    display: block;

    background: #fff;

    border-top: 1px solid #75263b;

    border-bottom: none;

    height: 100%;

}

    .article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }

    .article:hover .image-container img {

        -moz-transform: scale(1.05);

        -webkit-transform: scale(1.05);

        transform: scale(1.05);

    }

    .article .article-panel {

        padding-bottom: 60px;

    }

        .article .article-panel .news-detail-wrap {

            overflow: hidden;

            padding-bottom: 3px;

        }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.article .image-container img {

    width: 100%;

    -moz-transition: all 1s ease-out;

    -webkit-transition: all 1s ease-out;

    transition: all 1s ease-out;

}

 

.article h3 {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 600;

    display: inline;

    border-bottom: 1px solid transparent;

    -webkit-transition: border-color .4s ease-in-out;

    -moz-transition: border-color .4s ease-in-out;

    -o-transition: border-color .4s ease-in-out;

    transition: border-color .4s ease-in-out;

    overflow-wrap: break-word;

    word-wrap: break-word;

    word-break: break-word;

    -ms-word-break: break-all;

}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}



.article .date {



    left: 10px;

    bottom: 0px;

    display: block;

    margin-top: 10px;

}
 

.site-header.scroll-lock .icon-Search, .site-header.match-centre .icon-Search {

    font-size: 1.1em;

}

.icon-Search:before {

    content: "";

}

.article .category {

    display: block;

}

.article .article-panel {

    padding: 20px;

}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}

.grey-background {

    background: #f5f5f5;

}

.article .button-container, .article .synopsis {

    display: none;

}



.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}



.article .image-container .freemium-message, .article .image-container .premium-match-message, .article .image-container .premium-message, .article .image-container .event-message {

    display: none;

    visibility: hidden;

    position: absolute;

    background-color: #75263b;

    color: #fff;

    bottom: 0;

    font-size: 12px;

    font-size: .66667em;

    left: 50px;

    height: 50px;

    line-height: 50px;

    padding: 0 18px;

    z-index: 2;

}

.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}

.article.gallery .image-container, .article.video .image-container, .article.video-match .image-container, .article.video-hero .image-container {

    position: relative;

}



  

.article .image-container .freemium-message:before, .article .image-container .premium-match-message:before, .article .image-container .premium-message:before, .article .image-container .event-message:before {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

    content: "";

    margin-right: 10px;

    border-left: 1px solid rgba(255,255,255,.2);

    padding-left: 18px;

    margin-left: -18px;

}

.article .image-container .freemium-message .wide, .article .image-container .freemium-message .medium, .article .image-container .freemium-message .narrow, .article .image-container .premium-match-message .wide, .article .image-container .premium-match-message .medium, .article .image-container .premium-match-message .narrow, .article .image-container .premium-message .wide, .article .image-container .premium-message .medium, .article .image-container .premium-message .narrow, .article .image-container .event-message .wide, .article .image-container .event-message .medium, .article .image-container .event-message .narrow {

    display: none;

    visibility: hidden;

}

.article .image-container .freemium-message .medium, .article .image-container .premium-match-message .medium, .article .image-container .premium-message .medium, .article .image-container .event-message .medium {

    display: inline-block;

    visibility: visible;

}

h3, .h3 {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 600;

    font-size: 22px;

    font-size: 1.66667em;

    line-height: 1.36364em;

    margin-bottom: 20px;

}

.btn.btn-primary {

    background: #000000 !important;

    border: 1px solid #000000 !important;

    color: #fff;

    border-radius: 0px

}

    .btn.btn-primary:hover {

        background: #86c442 !important;

        border: 1px solid #86c442 !important;

        color: #fff;

        text-decoration: none;

    }

.btn, a.btn, .Form__Element .FormSubmitButton {

    display: inline-block;

    font-size: 18px !important;

    border: 0;

    text-decoration: none;

    background: none;

    padding: 13px;

    text-align: center;

    width: 100%;

    white-space: normal;

    -webkit-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -moz-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -o-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

}



.article-grid-container .button-container {

    display: block;

    clear: both;

    text-align: right;

    margin-bottom: 0;

    position: relative;

}



.footer {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    color: #fff;

    padding: 53.25px 3% 72px;

    margin-top: 30px;

}



    .footer h5 {

        margin-bottom: 30px;

        font-weight: bold;

    }

    .footer ul {

       margin:0px;

       padding:0px;

    }

        .footer ul li {

            list-style: none;

            margin-bottom: 20px;

        }

        .footer ul li a {

            text-decoration: none;

            color: #fff;

            border: none;

        }



#news-slider {

    margin-top: 0px;

}



.post-slide {

    background: #fff;

    margin: 20px 15px 20px;

    border-radius: 15px;

    padding-top: 1px;

    box-shadow: 0px 14px 22px -9px #bbcbd8;

}


 


        .post-slide .post-img img {

            width: 100%;

            height: auto;

            transform: scale(1,1);

            transition: transform 0.2s linear;

        }



    .post-slide:hover .post-img img {

        transform: scale(1.1,1.1);

    }



    .post-slide .over-layer {

        width: 100%;

        height: 100%;

        position: absolute;

        top: 0;

        left: 0;

        opacity: 0;

        background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);

        transition: all 0.50s linear;

    }



    .post-slide:hover .over-layer {

        opacity: 1;

        text-decoration: none;

    }



    .post-slide .over-layer i {

        position: relative;

        top: 45%;

        text-align: center;

        display: block;

        color: #fff;

        font-size: 25px;

    }



    .post-slide .post-content {

        background: #fff;

        padding: 2px 20px 40px;

        border-radius: 15px;

    }



    .post-slide .post-title a {

        font-size: 15px;

        font-weight: bold;

        color: #333;

        display: inline-block;

        text-transform: uppercase;

        transition: all 0.3s ease 0s;

    }



        .post-slide .post-title a:hover {

            text-decoration: none;

            color: #3498db;

        }



    .post-slide .post-description {

        line-height: 5px;

        color: #808080;

        margin-bottom: 0px;

        text-align: center;

    }



    .post-slide .post-date {

        color: #a9a9a9;

        font-size: 14px;

    }



        .post-slide .post-date i {

            font-size: 20px;

            margin-right: 8px;

            color: #CFDACE;

        }



    .post-slide .read-more {

        padding: 7px 20px;

        float: right;

        font-size: 12px;

        background: #2196F3;

        color: #ffffff;

        box-shadow: 0px 10px 20px -10px #1376c5;

        border-radius: 25px;

        text-transform: uppercase;

    }



        .post-slide .read-more:hover {

            background: #3498db;

            text-decoration: none;

            color: #fff;

        }



.owl-controls .owl-buttons {

    text-align: center;

    margin-top: 20px;

}



    .owl-controls .owl-buttons .owl-prev {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 80px;

        padding: 0 18px 0 15px;

        border-radius: 50px;

        box-shadow: 3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



    .owl-controls .owl-buttons .owl-next {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 15px;

        padding: 0 15px 0 18px;

        border-radius: 50px;

        box-shadow: -3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



        .owl-controls .owl-buttons .owl-prev:after,

        .owl-controls .owl-buttons .owl-next:after {

            content: "\f104";

            font-family: FontAwesome;

            color: #333;

            font-size: 30px;

        }



        .owl-controls .owl-buttons .owl-next:after {

            content: "\f105";

        }

h3.post-title {

    text-align: center;

}



.counter {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    padding-top: 5em;

    padding-bottom: 5em;

   

}



.page-heading {

    position: absolute;

    top: 40%;

    left: 50%;

    transform: translate(-50%, -50%);

    color: #aaa;

    white-space: nowrap;

}



.page-heading-primary {

    display: block;

    font-size: 3.125rem;

    font-weight: 300;

    color: #3498db;

}



.page-heading-secondary {

    display: block;

    font-size: 1.25rem;

    font-weight: 700;

    text-transform: uppercase;

    letter-spacing: 4.5px;

}

.number {

    width: 20%;

    float: left;

    margin: 20px;

    text-align: center;

    color: #f7a30a;

}

.clearfix {

    clear: both;

}

.count-text {

    color: #fff;

    font-weight: bold;

    margin-left: 25px;

}

.count {

    line-height: 100px;

    color: #f7a30a;

    margin-left: 30px;

    font-size: 4em;

}



.faq_section {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    padding-top: 5em;

    padding-bottom: 5em;

    color: #fff;

}

    .faq_section .accordion {

        margin-top: 2em;

    }

    .faq_section button.accordion-button.collapsed {

        font-size: 26px;

        text-align: left;

        background: #303030;

        color: #fff;

    }

    .faq_section .accordion-body {

        padding: 1rem 1.25rem;

        background: #303030;

        font-size: 26px;

    }

    .faq_section .accordion-item {

        border: 0px solid rgba(0,0,0,.125);

        margin-bottom: 10px;

    }

    .faq_section .accordion-button:not(.collapsed) {

        background-color: #303030;

        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);

        color: #fff;

        font-size: 26px;

        border-radius: 0px;

        margin-bottom: 1px;

    }

    .faq_section .accordion-item {

        background-color: #000;

    }

    .faq_section  .accordion-button::after {
    }

    .faq_section .accordion-button::after {
        width: 2.25rem;
        height: 2.25rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-size: 2rem;

    }

    .faq_section  .accordion-button::after {

        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");

        transform: scale(.7) !important;

        filter: brightness(0.1);

    }



    .faq_section  .accordion-button:not(.collapsed)::after {

        filter: brightness(0.1);

        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");

    }





@media only screen and (max-width:1280px) {

    .post-slide .post-content {

        padding: 0px 15px 25px 15px;

    }

}







@media (min-width: 1024px) {

    .container-fluid {

        padding-left: 30px;

        padding-right: 30px;

    }

    .news-grid .column-collapse {

        padding: 0;

        width: 25%;

        position: absolute;

        top: 0;

        right: 0;

    }

  

    .news-grid .large-image {

        padding-right: 25%;

        width: 100%;

        overflow: hidden;

        float: left;

    }

}


.form-group, .EPiServerForms .Form__Element {
    margin-bottom: 30px;
}
.c-login-form {
    max-width: 392px;
    padding: 0;
}
.login-section h2 {
    margin-bottom: 30px;
}
.login-separator {
    border-left: 2px solid #f5f5f5;
    text-align: center;
    position: relative;
}
.login-separator p {
    display: inline-block;
    margin: 0;
    background: #fff;
    padding: 10px 20px;
    font-size: 30px;
    font-size: 1.66667em;
    font-weight: 400;
    position: absolute;
    left: -35px;
    top: 50%;
    transform: translateY(-30px);
}
.form-control, .FormTextbox__Input, .EPiServerForms .FormDateTime .FormDateTime__Input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.hide {
  display: none;
}

.register_btn {
    width: 170px;
    float: left;
    margin-right: 25px;
}
.c-login-form__forgot-btn {
     width: auto;
    padding: 0;
    font-size: 16px!important;
    text-decoration: none;
    border: 0px;
    border-bottom: 1px solid #75263b;
    background: none;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .sp-template table td {
        padding: 5px; 
    }
    .has-number { 
        margin-bottom: 10px;
    }
.login-separator {
    border-left: 0;
    border-top: 2px solid #f5f5f5;
    margin-top: 40px;
    margin-bottom: -80px;    height: auto ! important;
}
    .dates { 
        padding: 5px 10px; 
        font-size: 14px; 
    }
.product-list h3{ font-size:18px;}

.inner-banner-header h1:after { 
    font-size: 50px;
    top: 0px; 
}
.inner-banner-header h1 {
    font-size: 26px; 
}
h3, .h3 { 
    margin-bottom: 5px;
}
p { 
    margin-bottom: 5px;
}
.register_btn {
    width: 150px;
    margin-right: 25px;
}
.login-separator p {
    position: relative;
    left: auto;
    top: 0;
    margin: 0 auto;
}
	.post-slide .post-img { 

    margin: 0px 15px 0px 15px; 

}
.search { 
        width: 100%;
    top: 0px;
    right: 50px;
    margin-bottom: 10px;
}
	.plus_icon {

    font-size: 1em !important;

}

    .main_head {

        margin-top:10em;

    }

	.count {

    font-size: 2em;  line-height: 40px;

}

.hide_mobile{

	

	display:none;

}





    .header .dropbtn {

        padding: 10px 10px;

        font-size: 14px;

    }

	.article .article-panel {

    padding: 10px;

	height:100% !important;

}

    .header {

       margin-top: 8px;
    float: left;
    margin-bottom: 10px;

    }

    .count {

        margin-left: 0px;

    }

    .number {

        width: 49%;

        margin: 0px;

    }

    .logo {text-align:center;

    }

    .counter {

        margin-top: 3em;

        padding-top: 3em;

        padding-bottom: 3em;

    }

    .count-text {
        margin-left: 0px;

    }
.login_sec{
	
	display: flex;
    flex-direction: column-reverse;
}

    .site-header .part.second .last {

        margin-right: 0px;

        padding-top: 0px;

    }

    nav.navbar.navbar-expand-lg.navbar-light {

        padding: 5px 0px;

    }

    .Opta-Tabs ul.Opta-Cf li { 
        width: 33%; 
    }
}
 
@font-face {

    font-family: 'icomoon';

    src: url('../fonts/icomoon.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Bold';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Bold.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Book';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Book.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Medium';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Medium.ttf') format('truetype');

}



@font-face {

    font-family: 'icomoon';

    src: url('../fonts/icomoon.eot') format('embedded-opentype');

}

@font-face {

    font-family: 'icons';

    src: url("../fonts/icomoon.eot?ym9sg2");

    src: url("../fonts/icomoon.eot?ym9sg2#iefix") format("embedded-opentype"), url("../fonts/icomoon.svg?ym9sg2#icomoon") format("svg"), url("../fonts/icomoon.woff2?ym9sg2") format("woff2"), url("../fonts/icomoon.ttf?ym9sg2") format("truetype"), url("../fonts/icomoon.woff?ym9sg2") format("woff");

    font-weight: normal;

    font-style: normal

}

body {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 400;

    color: #333;

}



.header_bg {

    background-image: url('./images/header-img.jpg');

    background-repeat: no-repeat;

}

/* DEMO-SPECIFIC STYLES */

.typewriter h1 {

    border-right: .15em solid orange; /* The typwriter cursor */

    margin: 0 auto; /* Gives that scrolling effect as the typing happens */

    letter-spacing: .15em; /* Adjust as needed */

    animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;

}



/* The typing effect */

@keyframes typing {

    from {

        width: 0

    }



    to {

        width: 100%

    }

}



/* The typewriter cursor effect */

@keyframes blink-caret {

    from, to {

        border-color: transparent

    }



    50% {

        border-color: orange

    }

}

.plus_icon {

    font-size: 2em !important;

    vertical-align: super;

}

.search {
    position: relative;
    box-shadow: 0 0 40px rgb(51 51 51 / 10%);
    float: left;
    width: 45%;
  top: 20px;
}

.search input {
    height: 45px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
}

.search input:focus {
    box-shadow: none;
    border: 2px solid blue
}

.search .fa-search {
    position: absolute;
    top: 15px;
    left: 16px;
	color:#a5a5a5;
}

.search button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue
}
.header {

    margin-top: 20px;

    float: right;

}





    .header .dropbtn {

        background-color: #000;

        color: white;

        padding: 10px 10px;

        font-size: 14px;

        border: none;

    }



    .header .dropdown {

        position: relative;

        display: inline-block;

        margin-left: 10px;

    }



.header .dropdown-content {

    display: none;

    position: absolute;

    background-color: #f1f1f1;

    min-width: 120px;

    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    z-index: 1;

}



    .header .dropdown-content a {

        color: black;

        padding: 12px 16px;

        text-decoration: none;

        display: block;

        border-bottom: 1px solid #86c442;

    

    }



        .header .dropdown-content a:hover {

            background-color: #e9ecef;

        }



.header .dropdown:hover .dropdown-content {

    display: block;

}



    .header .dropdown:hover .dropbtn {

        background-color: #86c442;

    }

.logo img {

    width: 135px;

    padding-top: 5px;

}

.site-header {

    position: relative;

    z-index: 5000;

}

    .site-header > div {

        background-color: #75263b;

        color: #fff;

        background-repeat: no-repeat;

        background-size: contain;

        background-position: top left;

        

        z-index: 999;

        top: 0;

        transition: height 0 ease;

        width: 100%;

    }

.site-header .overlay {

    background-color: #75263b;

    position: absolute;

    top: 0;

    right: 0;

    bottom: 0;

    left: 0;

    opacity: .15;

    display: none;

    z-index: 101;

}



.site-header .part.first {

    padding: 20px 0 22px 0;

}



    .site-header .vertical-center {

        display: table;

        height: 90px;

    }

    .site-header .part.first .club-badge {

        width: 90px;

        height: 90px;

        max-width: 100%;

        transition: width 0 ease,height 0 ease;

    }

.club-badge a {

    display: block;

    width: 100%;

    height: 100%;

    border: none;

}

.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}





.site-header .part.first h1 {

    margin: 0;

    font-size: 24px;

    font-size: 1.33333em;

    letter-spacing: .05em;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

    padding-left: 20px;

}

.site-header .vertical-center > * {

    display: table-cell;

    vertical-align: middle;

}



.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo span {

        display: block;

        text-transform: uppercase;

        padding-bottom: 6px;

    }

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }

.site-header .part.first .club-badge {

    width: 90px;

    height: 90px;

    max-width: 100%;

    transition: width 0 ease,height 0 ease;

}

.club-badge {

    display: block;

    margin: 0 auto;

    width: 90px;

    height: 90px;

    background-size: contain;

    background-repeat: no-repeat;

    background-position: center center;

    background-image: url('./images/abfc.png');

}



.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.vertical-center  h1 {

    font-size: 20px !important;

    font-size: 1.25em;

}



.site-header .part.first .partners .partner-logo img {

    display: block;

    margin: 0 auto;

    max-height: 60px;

    max-width: 100px;

}

.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }



.site-header .part.second {

    background-color: #fff;

    border-bottom: 1px solid #ccc;

    position: absolute;

    width: 100%;

    z-index: 104;

    margin-top: 0;

    transition: top 0 ease,margin-left 0 ease;

}



    .site-header .part.second ul {

        white-space: nowrap;

        list-style: none;

        padding-left: 0;

        margin: 0;

    }

    .site-header .part.second nav li.home-mobile-nav-link {

        display: none;

    }

    .site-header .part.second nav li a {

        display: inline-block;

        text-decoration: none;

        padding: 14px 10px 8px;

        border-bottom: 5px solid transparent;

        color: #333;

        font-size: 16px;

        font-size: .88889em;

        letter-spacing: .05em;

        font-family:  Typetanic-Fonts-Gibbs-Medium;

        font-weight: 800;

    }







.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



label {

    display: inline-block;

    max-width: 100%;

    margin-bottom: 5px;

    font-weight: bold;

}




.site-header .search .buttons {

    float: right;

}

    .site-header .search .buttons .btn.close {

        width: 30px;

        margin-right: 10px;

    }



.site-header .icon-Menu, .site-header .icon-Close, .site-header .icon-Search {

    color: #333;

}



[class^="link-icon-"]:before, [class*=" link-icon-"]:before, [class^="icon-"], [class*=" icon-"] {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.site-header .search .buttons .btn {

    width: 16px;

    height: 30px;

    padding: 0;

    margin-bottom: 0;

    font-size: .89em;

    line-height: 1em;

}



.site-header .search:not(.active) .icon-Search {

    margin-left: -1px;

    opacity: .5;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}

.site-header .part.second .last {

    margin-right: 0px;

    padding-top: 20px;

}



.pull-right {

    float: right !important;

}

.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li {

    display: inline-block;

}







.site-header .part.second .last .search-link {

    width: 30px;

    height: 30px;

    padding: 1px 0 0 1px;

    font-size: 16px;

    font-size: .88889em;

    margin-bottom: 0;

}

    .site-header .part.second .last .search-link .text {

        display: none;

    }



.site-header > div.headerbg-center {

    background-position: top center;

    background-size: contain;

}

.container-fluid:after {

    clear: both;

}



.container-fluid:before, .container-fluid:after {

    content: " ";

    display: table;

}





.site-header .part.second nav li.prioritynavigation-menu {

    border-left: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li a span.icon-Down-Arrow, .site-header .part.second nav li a span.icon-Up-Arrow {

    font-size: 12px;

    font-size: .66667em;

    display: inline-block;

    min-width: 1em;

}

.icon-Down-Arrow:before {

    content: "";

}

.site-header .on-open {

    display: none;

}

.site-header .on-closed {

    display: block;

}

/*.site-header .part.second nav li .dropdown-menu {

    display: none;

    padding: 3px 0;

}*/

.main_head {

    margin-top: 2em;

    background: #fff;

    padding: 30px 0px;

}

.site-header .part.second nav li ul {

    position: absolute;

    background: #ffffff;

    color: #333;

    padding: 0px 0;

    z-index: 100;

}



.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li:not(:last-child) {

    border-bottom: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li a {

    padding-top: 10px;

    padding-bottom: 10px;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

}

.site-header .part.second nav li .dropdown-menu.open {

    display: block;

}

.site-header .part.second nav li ul li.duplicated-link {

    display: none;

    visibility: hidden;

}

.site-header .part.second nav li ul li {

    display: block;

    border-bottom: 1px solid #86c442;

}









.site-header .part.first .partners {

    float: right;

}

.news-grid {

    background: #000;

    position: relative;

    overflow: hidden; margin-bottom: 40px;

}

.news-grid-article {

    width: 100%;

    height: 100%;

    position: relative;

    display: block;

    border: none;

}

    .news-grid-article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }



    .news-grid-article .overlay {

        background: -moz-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: -webkit-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#000000',GradientType=0);

        width: 100%;

        position: absolute;

        top: 0;

        bottom: 0;

        z-index: 10;

    }

    .news-grid-article .inner {

        padding: 40px;

    }



    .news-grid-article .inner {

        position: absolute;

        z-index: 100;

        bottom: 0;

        padding: 20px;

        color: #fff;

    }

    .news-grid-article .detail-block .news-detail-wrap, .news-grid-article .detail-block .block-inner {

        max-width: 1000px;

        overflow: hidden;

    }

    .news-grid-article .detail-block span {

        display: block;

    }

    .news-grid-article .detail-block h2 {

        margin-bottom: 0;

        border-bottom: 1px solid transparent;

        display: inline;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .news-grid-article .detail-block span.date {

        margin-top: 5px;

    }



    .news-grid-article .image-container img {

        width: 100%;

        -moz-transition: all 1s ease-out;

        -webkit-transition: all 1s ease-out;

        transition: all 1s ease-out;

    }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.column-collapse .news-grid-article .detail-block h2 {

    font-size: 18px;

    font-size: 1em;

}

h2, .h2 {

    font-weight: 600;

}

.site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

    position: fixed;

    top: 0;

    left: 0;

    right: 0;

    border-top: none;

    height: 50px;

}



.site-header.scroll-lock:not(.navigation-active), .site-header.match-centre:not(.navigation-active) {

    height: 140px;

}

    .site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

        position: fixed;

        top: 0;

        left: 0;

        right: 0;

        border-top: none;

        height: 50px;

    }

    .site-header.scroll-lock:not(.navigation-active) > div, .site-header.match-centre:not(.navigation-active) > div {

        height: 50px;

    }





.sticky {

    position: fixed !important;

    top: 0;

    width: 100%;

}



    .sticky .site-header > div.headerbg-center {

        margin-top: -133px;

    }

nav.navbar.navbar-expand-lg.navbar-light {

    padding: 20px 0px;

}

    .sticky + .content {

        padding-top: 102px;

    }



.nav-tabs > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

}



#exTab1 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



#exTab2 h3 {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



/* remove border radius for the tab */



#exTab1 .nav-pills > li > a {

    border-radius: 0;

}



/* change border radius for the tab , apply corners on top*/



#exTab3 .nav-pills > li > a {

    border-radius: 4px 4px 0 0;

}







.nav-tabs {

    border-bottom: 1px solid #ddd;

}

    .nav-tabs > li {

        float: left;

        margin-bottom: -1px;

    }

        .nav-tabs > li > a {

            margin-right: 2px;

            line-height: 1.42857143;

            border: 1px solid transparent;

            border-radius: 4px 4px 0 0;

        }



.nav > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

    color: #333;

    text-decoration: none;

    border-bottom: none;

}

   

    .nav > li > a.active {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .nav > li > a:hover {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

.tab-content {

    padding-top: 30px;

}

#exTab3 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}





.article {

    position: relative;

    display: block;

    background: #fff;

    border-top: 1px solid #75263b;

    border-bottom: none;

    height: 100%;

    box-shadow: 0px 14px 22px -9px #bbcbd8;

}

    .article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }

    .article:hover .image-container img {

        -moz-transform: scale(1.05);

        -webkit-transform: scale(1.05);

        transform: scale(1.05);

    }

    .article .article-panel {

        padding-bottom: 60px;

    }

        .article .article-panel .news-detail-wrap {

            overflow: hidden;

            padding-bottom: 3px;

        }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.article .image-container img {

    width: 100%;

    -moz-transition: all 1s ease-out;

    -webkit-transition: all 1s ease-out;

    transition: all 1s ease-out;

}

 



.article h3 {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 600;

    display: inline;

    border-bottom: 1px solid transparent;

    -webkit-transition: border-color .4s ease-in-out;

    -moz-transition: border-color .4s ease-in-out;

    -o-transition: border-color .4s ease-in-out;

    transition: border-color .4s ease-in-out;

    overflow-wrap: break-word;

    word-wrap: break-word;

    word-break: break-word;

    -ms-word-break: break-all;

}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}



.article .date {



    left: 10px;

    bottom: 0px;

    display: block;

    margin-top: 10px;

}

 

.site-header.scroll-lock .icon-Search, .site-header.match-centre .icon-Search {

    font-size: 1.1em;

}

.icon-Search:before {

    content: "";

}

.article .category {

    display: block;

}

.article .article-panel {

    padding: 20px;

}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}

.grey-background {

    background: #f5f5f5;

}

.article .button-container, .article .synopsis {

    display: none;

}



.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}



.article .image-container .freemium-message, .article .image-container .premium-match-message, .article .image-container .premium-message, .article .image-container .event-message {

    display: none;

    visibility: hidden;

    position: absolute;

    background-color: #75263b;

    color: #fff;

    bottom: 0;

    font-size: 12px;

    font-size: .66667em;

    left: 50px;

    height: 50px;

    line-height: 50px;

    padding: 0 18px;

    z-index: 2;

}

.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}

.article.gallery .image-container, .article.video .image-container, .article.video-match .image-container, .article.video-hero .image-container {

    position: relative;

}



  

.article .image-container .freemium-message:before, .article .image-container .premium-match-message:before, .article .image-container .premium-message:before, .article .image-container .event-message:before {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

    content: "";

    margin-right: 10px;

    border-left: 1px solid rgba(255,255,255,.2);

    padding-left: 18px;

    margin-left: -18px;

}

.article .image-container .freemium-message .wide, .article .image-container .freemium-message .medium, .article .image-container .freemium-message .narrow, .article .image-container .premium-match-message .wide, .article .image-container .premium-match-message .medium, .article .image-container .premium-match-message .narrow, .article .image-container .premium-message .wide, .article .image-container .premium-message .medium, .article .image-container .premium-message .narrow, .article .image-container .event-message .wide, .article .image-container .event-message .medium, .article .image-container .event-message .narrow {

    display: none;

    visibility: hidden;

}

.article .image-container .freemium-message .medium, .article .image-container .premium-match-message .medium, .article .image-container .premium-message .medium, .article .image-container .event-message .medium {

    display: inline-block;

    visibility: visible;

}

h3, .h3 {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 600;

    font-size: 22px;

    font-size: 1.66667em;

    line-height: 1.36364em;

    margin-bottom: 20px;

}

.btn.btn-primary {

    background: #000000 !important;

    border: 1px solid #000000 !important;

    color: #fff;

    border-radius: 0px

}

    .btn.btn-primary:hover {

        background: #86c442 !important;

        border: 1px solid #86c442 !important;

        color: #fff;

        text-decoration: none;

    }

.btn, a.btn, .Form__Element .FormSubmitButton {

    display: inline-block;

    font-size: 18px !important;

    border: 0;

    text-decoration: none;

    background: none;

    padding: 13px;

    text-align: center;

    width: 100%;

    white-space: normal;

    -webkit-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -moz-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -o-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

}



.article-grid-container .button-container {

    display: block;

    clear: both;

    text-align: right;

    margin-bottom: 0;

    position: relative;

}



.footer {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    color: #fff;

    padding: 53.25px 3% 72px;

    margin-top: 30px;

}



    .footer h5 {

        margin-bottom: 30px;

        font-weight: bold;

    }

    .footer ul {

       margin:0px;

       padding:0px;

    }

        .footer ul li {

            list-style: none;

            margin-bottom: 20px;

        }

        .footer ul li a {

            text-decoration: none;

            color: #fff;

            border: none;

        }



#news-slider {

    margin-top: 0px;

}



.post-slide {

    background: #fff;

    margin: 20px 15px 20px;

    border-radius: 15px;

    padding-top: 1px;

    box-shadow: 0px 14px 22px -9px #bbcbd8;

}



   



        .post-slide .post-img img {

            width: 100%;

            height: auto;

            transform: scale(1,1);

            transition: transform 0.2s linear;

        }



    .post-slide:hover .post-img img {

        transform: scale(1.1,1.1);

    }



    .post-slide .over-layer {

        width: 100%;

        height: 100%;

        position: absolute;

        top: 0;

        left: 0;

        opacity: 0;

        background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);

        transition: all 0.50s linear;

    }



    .post-slide:hover .over-layer {

        opacity: 1;

        text-decoration: none;

    }



    .post-slide .over-layer i {

        position: relative;

        top: 45%;

        text-align: center;

        display: block;

        color: #fff;

        font-size: 25px;

    }



    .post-slide .post-content {

        background: #fff;

        padding: 2px 20px 40px;

        border-radius: 15px;

    }



    .post-slide .post-title a {

        font-size: 15px;

        font-weight: bold;

        color: #333;

        display: inline-block;

        text-transform: uppercase;

        transition: all 0.3s ease 0s;

    }



        .post-slide .post-title a:hover {

            text-decoration: none;

            color: #3498db;

        }



    .post-slide .post-description {

        line-height: 5px;

        color: #808080;

        margin-bottom: 0px;

        text-align: center;

    }



    .post-slide .post-date {

        color: #a9a9a9;

        font-size: 14px;

    }



        .post-slide .post-date i {

            font-size: 20px;

            margin-right: 8px;

            color: #CFDACE;

        }



    .post-slide .read-more {

        padding: 7px 20px;

        float: right;

        font-size: 12px;

        background: #2196F3;

        color: #ffffff;

        box-shadow: 0px 10px 20px -10px #1376c5;

        border-radius: 25px;

        text-transform: uppercase;

    }



        .post-slide .read-more:hover {

            background: #3498db;

            text-decoration: none;

            color: #fff;

        }



.owl-controls .owl-buttons {

    text-align: center;

    margin-top: 20px;

}



    .owl-controls .owl-buttons .owl-prev {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 80px;

        padding: 0 18px 0 15px;

        border-radius: 50px;

        box-shadow: 3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



    .owl-controls .owl-buttons .owl-next {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 15px;

        padding: 0 15px 0 18px;

        border-radius: 50px;

        box-shadow: -3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



        .owl-controls .owl-buttons .owl-prev:after,

        .owl-controls .owl-buttons .owl-next:after {

            content: "\f104";

            font-family: FontAwesome;

            color: #333;

            font-size: 30px;

        }



        .owl-controls .owl-buttons .owl-next:after {

            content: "\f105";

        }

h3.post-title {

    text-align: center;

}



.counter {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    padding-top: 5em;

    padding-bottom: 5em;

   

}



.page-heading {

    position: absolute;

    top: 40%;

    left: 50%;

    transform: translate(-50%, -50%);

    color: #aaa;

    white-space: nowrap;

}



.page-heading-primary {

    display: block;

    font-size: 3.125rem;

    font-weight: 300;

    color: #3498db;

}



.page-heading-secondary {

    display: block;

    font-size: 1.25rem;

    font-weight: 700;

    text-transform: uppercase;

    letter-spacing: 4.5px;

}

.number {

    width: 20%;

    float: left;

    margin: 20px;

    text-align: center;

    color: #f7a30a;

}

.clearfix {

    clear: both;

}

.count-text {

    color: #fff;

    font-weight: bold;

    margin-left: 25px;

}

.count {

    line-height: 100px;

    color: #f7a30a;

    margin-left: 30px;

    font-size: 4em;

}





@media only screen and (max-width:1280px) {

    .post-slide .post-content {

        padding: 0px 15px 25px 15px;

    }

}







@media (min-width: 1024px) {

    .container-fluid {

        padding-left: 30px;

        padding-right: 30px;

    }

    .news-grid .column-collapse {

        padding: 0;

        width: 25%;

        position: absolute;

        top: 0;

        right: 0;

    }

  

    .news-grid .large-image {

        padding-right: 25%;

        width: 100%;

        overflow: hidden;

        float: left;

    }

}


.form-group, .EPiServerForms .Form__Element {
    margin-bottom: 30px;
}
.c-login-form {
    max-width: 392px;
    padding: 0;
}
.login-section h2 {
    margin-bottom: 30px;
}
.login-separator {
    border-left: 2px solid #f5f5f5;
    text-align: center;
    position: relative;
}
.login-separator p {
    display: inline-block;
    margin: 0;
    background: #fff;
    padding: 10px 20px;
    font-size: 30px;
    font-size: 1.66667em;
    font-weight: 400;
    position: absolute;
    left: -35px;
    top: 50%;
    transform: translateY(-30px);
}
.form-control, .FormTextbox__Input, .EPiServerForms .FormDateTime .FormDateTime__Input {
    display: block;
    width: 100%;
    height: 45px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.hide {
  display: none;
}

.register_btn {
    width: 170px;
    float: left;
    margin-right: 25px;
}
.c-login-form__forgot-btn {
     width: auto;
    padding: 0;
    font-size: 16px!important;
    text-decoration: none;
    border: 0px;
    border-bottom: 1px solid #75263b;
    background: none;
    margin-top: 10px;
}
.fa-long-arrow-right {
    margin-left: 5px;
    margin-top: 0px;
    vertical-align: middle;
}
@media (max-width: 767px) {
    .checkout-section .quantity input {
        height: 15px;
        width: 15px;
        font-size: 10px;
    }
    .img-fluid { 
        height: auto !important;
      
    }
    .checkout-section td {
        padding: 2px;
      
    }
    .quantity .minus, .plus {
        width: 15px;
        height: 15px;
        padding: 0px 0px 0px 0px;
        font-size: 10px;
        line-height: 13px;
    }
    tbody, td, tfoot, th, thead, tr {
     
        font-size: 12px;
    }
    .cart-product .h6, h6 {
        font-size: 12px;
    }
.login-separator {
    border-left: 0;
    border-top: 2px solid #f5f5f5;
    margin-top: 40px;
    margin-bottom: -80px;    height: auto ! important;
}
.register_btn {
    width: 150px;
    margin-right: 25px;
}
    .merchant_txt {
        text-align: left !important;
        font-size: 24px;
    }
    
    
    .login-separator p {
        position: relative;
        left: auto;
        top: 0;
        margin: 0 auto;
    }
	.post-slide .post-img { 

    margin: 0px 15px 0px 15px; 

}
.search { 
        width: 100%;
    top: 0px;
    right: 50px;
    margin-bottom: 10px;
}
	.plus_icon {

    font-size: 1em !important;

}

    .main_head {

        margin-top:10em;

    }

	.count {

    font-size: 2em;  line-height: 40px;

}

.hide_mobile{

	

	display:none;

}





    .header .dropbtn {

        padding: 10px 10px;

        font-size: 14px;

    }

	.article .article-panel {

    padding: 10px;

	height:100% !important;

}

    .header {

       margin-top: 8px;
    float: left;
    margin-bottom: 10px;

    }

    .count {

        margin-left: 0px;

    }

    .number {

        width: 49%;

        margin: 0px;

    }

    .logo {text-align:center;

    }

    .counter {

        margin-top: 3em;

        padding-top: 3em;

        padding-bottom: 3em;

    }

    .count-text {
        margin-left: 0px;

    }
.login_sec{
	
	display: flex;
    flex-direction: column-reverse;
}

    .site-header .part.second .last {

        margin-right: 0px;

        padding-top: 0px;

    }

    nav.navbar.navbar-expand-lg.navbar-light {

        padding: 5px 0px;

    }

}
 
@font-face {

    font-family: 'icomoon';

    src: url('../fonts/icomoon.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Bold';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Bold.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Book';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Book.ttf') format('truetype');

}

@font-face {

    font-family: 'Typetanic-Fonts-Gibbs-Medium';

    src: url('../fonts/Typetanic-Fonts-Gibbs-Medium.ttf') format('truetype');

}



@font-face {

    font-family: 'icomoon';

    src: url('../fonts/icomoon.eot') format('embedded-opentype');

}

@font-face {

    font-family: 'icons';

    src: url("../fonts/icomoon.eot?ym9sg2");

    src: url("../fonts/icomoon.eot?ym9sg2#iefix") format("embedded-opentype"), url("../fonts/icomoon.svg?ym9sg2#icomoon") format("svg"), url("../fonts/icomoon.woff2?ym9sg2") format("woff2"), url("../fonts/icomoon.ttf?ym9sg2") format("truetype"), url("../fonts/icomoon.woff?ym9sg2") format("woff");

    font-weight: normal;

    font-style: normal

}

body {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 400;

    color: #333;

}



.header_bg {

    background-image: url('./images/header-img.jpg');

    background-repeat: no-repeat;

}

/* DEMO-SPECIFIC STYLES */

.typewriter h1 {

    border-right: .15em solid orange; /* The typwriter cursor */

    margin: 0 auto; /* Gives that scrolling effect as the typing happens */

    letter-spacing: .15em; /* Adjust as needed */

    animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;

}



/* The typing effect */

@keyframes typing {

    from {

        width: 0

    }



    to {

        width: 100%

    }

}



/* The typewriter cursor effect */

@keyframes blink-caret {

    from, to {

        border-color: transparent

    }



    50% {

        border-color: orange

    }

}

.plus_icon {

    font-size: 2em !important;

    vertical-align: super;

}

.search {
    position: relative;
    box-shadow: 0 0 40px rgb(51 51 51 / 10%);
    float: left;
    width: 40%;
  top: 20px;
}

.search input {
    height: 45px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
}

.search input:focus {
    box-shadow: none;
    border: 2px solid #8cc847
}

.search .fa-search {
    position: absolute;
    top: 15px;
    left: 16px;
	color:#a5a5a5;
}

.search button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue
}
.header {

    margin-top: 20px;

    float: right;

}





    .header .dropbtn {

        background-color: #000;

        color: white;

        padding: 10px 10px;

        font-size: 14px;

        border: none;

    }



    .header .dropdown {

        position: relative;

        display: inline-block;

        margin-left: 10px;

    }



.header .dropdown-content {

    display: none;

    position: absolute;

    background-color: #f1f1f1;

    min-width: 120px;

    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    z-index: 1;

}



    .header .dropdown-content a {

        color: black;

        padding: 12px 16px;

        text-decoration: none;

        display: block;

        border-bottom: 1px solid #86c442;

    

    }



        .header .dropdown-content a:hover {

            background-color: #e9ecef;

        }



.header .dropdown:hover .dropdown-content {

    display: block;

}



    .header .dropdown:hover .dropbtn {

        background-color: #86c442;

    }

.logo img {

    width: 135px;

    padding-top: 5px;

}

.site-header {

    position: relative;

    z-index: 9;

}

    .site-header > div {

        background-color: #75263b;

        color: #fff;

        background-repeat: no-repeat;

        background-size: contain;

        background-position: top left;

        

        z-index: 999;

        top: 0;

        transition: height 0 ease;

        width: 100%;

    }

.site-header .overlay {

    background-color: #75263b;

    position: absolute;

    top: 0;

    right: 0;

    bottom: 0;

    left: 0;

    opacity: .15;

    display: none;

    z-index: 101;

}



.site-header .part.first {

    padding: 20px 0 22px 0;

}



    .site-header .vertical-center {

        display: table;

        height: 90px;

    }

    .site-header .part.first .club-badge {

        width: 90px;

        height: 90px;

        max-width: 100%;

        transition: width 0 ease,height 0 ease;

    }

.club-badge a {

    display: block;

    width: 100%;

    height: 100%;

    border: none;

}

.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}





.site-header .part.first h1 {

    margin: 0;

    font-size: 24px;

    font-size: 1.33333em;

    letter-spacing: .05em;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

    padding-left: 20px;

}

.site-header .vertical-center > * {

    display: table-cell;

    vertical-align: middle;

}



.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo span {

        display: block;

        text-transform: uppercase;

        padding-bottom: 6px;

    }

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }

.site-header .part.first .club-badge {

    width: 90px;

    height: 90px;

    max-width: 100%;

    transition: width 0 ease,height 0 ease;

}

.club-badge {

    display: block;

    margin: 0 auto;

    width: 90px;

    height: 90px;

    background-size: contain;

    background-repeat: no-repeat;

    background-position: center center;

    background-image: url('./images/abfc.png');

}



.site-header .part.first .club-badge .sr-only {

    font-size: 5px;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.vertical-center  h1 {

    font-size: 20px !important;

    font-size: 1.25em;

}



.site-header .part.first .partners .partner-logo img {

    display: block;

    margin: 0 auto;

    max-height: 60px;

    max-width: 100px;

}

.site-header .part.first .partners .partner-logo.club-partner {

    max-height: 50px;

    padding-top: 0;

}



.site-header .part.first .partners .partner-logo:first-child {

    margin-left: 0;

}



.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

.site-header .part.first .partners .partner-logo {

    text-align: center;

    padding-left: 35px;

    font-size: 9px;

    font-size: .5em;

    padding-top: 12px;

}

    .site-header .part.first .partners .partner-logo img {

        display: block;

        margin: 0 auto;

        max-height: 60px;

        max-width: 100px;

    }



.site-header .part.second {

    background-color: #fff;

    border-bottom: 1px solid #ccc;

    position: absolute;

    width: 100%;

    z-index: 104;

    margin-top: 0;

    transition: top 0 ease,margin-left 0 ease;

}



    .site-header .part.second ul {

        white-space: nowrap;

        list-style: none;

        padding-left: 0;

        margin: 0;

    }

    .site-header .part.second nav li.home-mobile-nav-link {

        display: none;

    }

    .site-header .part.second nav li a {

        display: inline-block;

        text-decoration: none;

        padding: 14px 8px 8px;

        border-bottom: 5px solid transparent;

        color: #333;

        font-size: 16px;

        font-size: .88889em;

        letter-spacing: .05em;

        font-family:  Typetanic-Fonts-Gibbs-Medium;

        font-weight: 800;

    }







.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}

textarea.form-control { 
    height: auto;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
 
    font-size: 14px;
 
    font-size: 17px;
 
    font-weight: bold;
}

.input-group-text { 
    font-size: 0.8rem; 
}


.site-header .search .buttons {

    float: right;

}

    .site-header .search .buttons .btn.close {

        width: 30px;

        margin-right: 10px;

    }



.site-header .icon-Menu, .site-header .icon-Close, .site-header .icon-Search {

    color: #333;

}



[class^="link-icon-"]:before, [class*=" link-icon-"]:before, [class^="icon-"], [class*=" icon-"] {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}



.site-header .search .buttons .btn {

    width: 16px;

    height: 30px;

    padding: 0;

    margin-bottom: 0;

    font-size: .89em;

    line-height: 1em;

}



.site-header .search:not(.active) .icon-Search {

    margin-left: -1px;

    opacity: .5;

}



.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    margin: -1px;

    padding: 0;

    overflow: hidden;

    clip: rect(0,0,0,0);

    border: 0;

}

.site-header .part.second .last {

    margin-right: 0px;

    padding-top: 20px;

}



.pull-right {

    float: right !important;

}

.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li {

    display: inline-block;

}







.site-header .part.second .last .search-link {

    width: 30px;

    height: 30px;

    padding: 1px 0 0 1px;

    font-size: 16px;

    font-size: .88889em;

    margin-bottom: 0;

}

    .site-header .part.second .last .search-link .text {

        display: none;

    }



.site-header > div.headerbg-center {

    background-position: top center;

    background-size: contain;

}

.container-fluid:after {

    clear: both;

}



.container-fluid:before, .container-fluid:after {

    content: " ";

    display: table;

}





.site-header .part.second nav li.prioritynavigation-menu {

    border-left: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li a span.icon-Down-Arrow, .site-header .part.second nav li a span.icon-Up-Arrow {

    font-size: 12px;

    font-size: .66667em;

    display: inline-block;

    min-width: 1em;

}

.icon-Down-Arrow:before {

    content: "";

}

.site-header .on-open {

    display: none;

}

.site-header .on-closed {

    display: block;

}

/*.site-header .part.second nav li .dropdown-menu {

    display: none;

    padding: 3px 0;

}*/

.main_head {

    margin-top: 2em;

    background: #fff;

    padding: 30px 0px;

}

.site-header .part.second nav li ul {

    position: absolute;

    background: #ffffff;

    color: #333;

    padding: 0px 0;

    z-index: 100;

}



.site-header .part.second ul {

    white-space: nowrap;

    list-style: none;

    padding-left: 0;

    margin: 0;

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li:not(:last-child) {

    border-bottom: 1px solid rgba(0,0,0,.15);

}

.site-header .part.second nav li.prioritynavigation-menu .dropdown-menu li a {

    padding-top: 10px;

    padding-bottom: 10px;

    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;

    font-weight: 800;

}

.site-header .part.second nav li .dropdown-menu.open {

    display: block;

}

.site-header .part.second nav li ul li.duplicated-link {

    display: none;

    visibility: hidden;

}

.site-header .part.second nav li ul li {

    display: block;

    border-bottom: 1px solid #86c442;

}









.site-header .part.first .partners {

    float: right;

}

.news-grid {

    background: #000;

    position: relative;

    overflow: hidden; margin-bottom: 40px;

}

.news-grid-article {

    width: 100%;

    height: 100%;

    position: relative;

    display: block;

    border: none;

}

    .news-grid-article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }



    .news-grid-article .overlay {

        background: -moz-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: -webkit-linear-gradient(top,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,.5) 80%,#000 100%);

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#000000',GradientType=0);

        width: 100%;

        position: absolute;

        top: 0;

        bottom: 0;

        z-index: 10;

    }

    .news-grid-article .inner {

        padding: 40px;

    }



    .news-grid-article .inner {

        position: absolute;

        z-index: 100;

        bottom: 0;

        padding: 20px;

        color: #fff;

    }

    .news-grid-article .detail-block .news-detail-wrap, .news-grid-article .detail-block .block-inner {

        max-width: 1000px;

        overflow: hidden;

    }

    .news-grid-article .detail-block span {

        display: block;

    }

    .news-grid-article .detail-block h2 {

        margin-bottom: 0;

        border-bottom: 1px solid transparent;

        display: inline;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .news-grid-article .detail-block span.date {

        margin-top: 5px;

    }



    .news-grid-article .image-container img {

        width: 100%;

        -moz-transition: all 1s ease-out;

        -webkit-transition: all 1s ease-out;

        transition: all 1s ease-out;

    }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.column-collapse .news-grid-article .detail-block h2 {

    font-size: 18px;

    font-size: 1em;

}

h2, .h2 {

    font-weight: 600;

}

.site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

    position: fixed;

    top: 0;

    left: 0;

    right: 0;

    border-top: none;

    height: 50px;

}



.site-header.scroll-lock:not(.navigation-active), .site-header.match-centre:not(.navigation-active) {

    height: 140px;

}

    .site-header.scroll-lock:not(.navigation-active) .part.second, .site-header.match-centre:not(.navigation-active) .part.second {

        position: fixed;

        top: 0;

        left: 0;

        right: 0;

        border-top: none;

        height: 50px;

    }

    .site-header.scroll-lock:not(.navigation-active) > div, .site-header.match-centre:not(.navigation-active) > div {

        height: 50px;

    }





.sticky {

    position: fixed !important;

    top: 0;

    width: 100%;

}



    .sticky .site-header > div.headerbg-center {

        margin-top: -133px;

    }

nav.navbar.navbar-expand-lg.navbar-light {

    padding: 20px 0px;

}

    .sticky + .content {

        padding-top: 102px;

    }



.nav-tabs > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

}



#exTab1 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



#exTab2 h3 {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}



/* remove border radius for the tab */



#exTab1 .nav-pills > li > a {

    border-radius: 0;

}



/* change border radius for the tab , apply corners on top*/



#exTab3 .nav-pills > li > a {

    border-radius: 4px 4px 0 0;

}







.nav-tabs {

    border-bottom: 1px solid #ddd;

}

    .nav-tabs > li {

        float: left;

        margin-bottom: -1px;

    }

        .nav-tabs > li > a {

            margin-right: 2px;

            line-height: 1.42857143;

            border: 1px solid transparent;

            border-radius: 4px 4px 0 0;

        }



.nav > li > a {

    position: relative;

    display: block;

    padding: 10px 15px;

    color: #333;

    text-decoration: none;

    border-bottom: none;

}

   

    .nav > li > a.active {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

    .nav > li > a:hover {

        border-bottom: 4px solid #75263b;

        -webkit-transition: border-color .4s ease-in-out;

        -moz-transition: border-color .4s ease-in-out;

        -o-transition: border-color .4s ease-in-out;

        transition: border-color .4s ease-in-out;

    }

.tab-content {

    padding-top: 30px;

}

#exTab3 .tab-content {

    color: white;

    background-color: #428bca;

    padding: 5px 15px;

}





.article {

    position: relative;

    display: block;

    background: #fff;

    border-top: 1px solid #75263b;

    border-bottom: none;

    height: 100%;

    box-shadow: 0px 14px 22px -9px #bbcbd8;

}

    .article .image-container {

        width: 100%;

        height: 0;

        padding-bottom: 56%;

        overflow: hidden;

    }

    .article:hover .image-container img {

        -moz-transform: scale(1.05);

        -webkit-transform: scale(1.05);

        transform: scale(1.05);

    }

    .article .article-panel {

        padding-bottom: 60px;

    }

        .article .article-panel .news-detail-wrap {
            overflow: hidden;
            color: #000;
            padding-bottom: 3px;
        }

.detail {

    font-size: 12px;

    font-size: .66667em;

    line-height: 1.66667em;

}

.article .image-container img {

    width: 100%;

    -moz-transition: all 1s ease-out;

    -webkit-transition: all 1s ease-out;

    transition: all 1s ease-out;

}

 


.article h3 {
    font-family: Typetanic-Fonts-Gibbs-Medium;
    font-weight: 600;
    color: #000;
    display: inline; 
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}



.article .date {



    left: 10px;

    bottom: 0px;

    display: block;

    margin-top: 10px;

}

 

.site-header.scroll-lock .icon-Search, .site-header.match-centre .icon-Search {

    font-size: 1.1em;

}

.icon-Search:before {

    content: "";

}

.article .category {

    display: block;

}

.article .article-panel {

    padding: 20px;

}

.article .date {

    left: 20px;

    bottom: 20px -5px;

}

.grey-background {

    background: #f5f5f5;

}

.article .button-container, .article .synopsis {

    display: none;

}



.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}



.article .image-container .freemium-message, .article .image-container .premium-match-message, .article .image-container .premium-message, .article .image-container .event-message {

    display: none;

    visibility: hidden;

    position: absolute;

    background-color: #75263b;

    color: #fff;

    bottom: 0;

    font-size: 12px;

    font-size: .66667em;

    left: 50px;

    height: 50px;

    line-height: 50px;

    padding: 0 18px;

    z-index: 2;

}

.article.premium-video .image-container .premium-message {

    display: block;

    visibility: visible;

}

.article.gallery .image-container, .article.video .image-container, .article.video-match .image-container, .article.video-hero .image-container {

    position: relative;

}



  

.article .image-container .freemium-message:before, .article .image-container .premium-match-message:before, .article .image-container .premium-message:before, .article .image-container .event-message:before {

    font-family: 'icons' !important;

    speak: none;

    font-style: normal;

    font-weight: normal;

    font-variant: normal;

    text-transform: none;

    line-height: 1;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

    content: "";

    margin-right: 10px;

 

    padding-left: 18px;

    margin-left: -18px;

}

.article .image-container .freemium-message .wide, .article .image-container .freemium-message .medium, .article .image-container .freemium-message .narrow, .article .image-container .premium-match-message .wide, .article .image-container .premium-match-message .medium, .article .image-container .premium-match-message .narrow, .article .image-container .premium-message .wide, .article .image-container .premium-message .medium, .article .image-container .premium-message .narrow, .article .image-container .event-message .wide, .article .image-container .event-message .medium, .article .image-container .event-message .narrow {

    display: none;

    visibility: hidden;

}

.article .image-container .freemium-message .medium, .article .image-container .premium-match-message .medium, .article .image-container .premium-message .medium, .article .image-container .event-message .medium {

    display: inline-block;

    visibility: visible;

}

h3, .h3 {

    font-family: Typetanic-Fonts-Gibbs-Medium;

    font-weight: 600;

    font-size: 22px;

    font-size: 1.66667em;

    line-height: 1.36364em;

    margin-bottom: 20px;

}

.btn.btn-primary {

    background: #000000 !important;

    border: 1px solid #000000 !important;

    color: #fff;

    border-radius: 0px

}

    .btn.btn-primary:hover {

        background: #86c442 !important;

        border: 1px solid #86c442 !important;

        color: #fff;

        text-decoration: none;

    }

.btn, a.btn, .Form__Element .FormSubmitButton {

    display: inline-block;

    font-size: 18px !important;

    border: 0;

    text-decoration: none;

    background: none;

    padding: 13px;

    text-align: center;

    width: 100%;

    white-space: normal;

    -webkit-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -moz-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    -o-transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

    transition: color .5s linear,background-color .5s ease-in-out,border-color .4s ease-in-out;

}



.article-grid-container .button-container {

    display: block;

    clear: both;

    text-align: right;

    margin-bottom: 0;

    position: relative;

}



.footer {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    color: #fff;

    padding: 53.25px 3% 72px;

    margin-top: 30px;

}



    .footer h5 {

        margin-bottom: 30px;

        font-weight: bold;

    }

    .footer ul {

       margin:0px;

       padding:0px;

    }

        .footer ul li {

            list-style: none;

            margin-bottom: 20px;

        }

        .footer ul li a {

            text-decoration: none;

            color: #fff;

            border: none;

        }



#news-slider {

    margin-top: 0px;

}

.flag-icon{ width:30px; }

.sale_btn{ width:100px;     padding: 2px 0px 15px 0px; 
 
border-radius:0px;margin-top:0px;
}

.post-slide {

    background: #fff;

    margin: 20px 15px 20px;

    border-radius:0px;

    padding-top: 1px;

    box-shadow: 0px 14px 22px -9px #bbcbd8;

}



    .post-slide .post-img {

        position: relative;

        overflow: hidden;

        border-radius: 10px;

        margin: 0px 15px 8px 15px;

  

    }



        .post-slide .post-img img {

            width: 100%;

            height: auto;

            transform: scale(1,1);

            transition: transform 0.2s linear;

        }



    .post-slide:hover .post-img img {

        transform: scale(1.1,1.1);

    }



    .post-slide .over-layer {

        width: 100%;

        height: 100%;

        position: absolute;

        top: 0;

        left: 0;

        opacity: 0;

        background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);

        transition: all 0.50s linear;

    }



    .post-slide:hover .over-layer {

        opacity: 1;

        text-decoration: none;

    }



    .post-slide .over-layer i {

        position: relative;

        top: 45%;

        text-align: center;

        display: block;

        color: #fff;

        font-size: 25px;

    }



    .post-slide .post-content {

        background: #fff;

        padding: 2px 20px 40px;

        border-radius: 15px;

    }



    .post-slide .post-title a {

        font-size: 15px;

        font-weight: bold;

        color: #333;

        display: inline-block;

        text-transform: uppercase;

        transition: all 0.3s ease 0s;

    }



        .post-slide .post-title a:hover {

            text-decoration: none;

            color: #3498db;

        }



    .post-slide .post-description {

        line-height: 5px;

        color: #808080;

        margin-bottom: 0px;

        text-align: center;

    }



    .post-slide .post-date {

        color: #a9a9a9;

        font-size: 14px;

    }



        .post-slide .post-date i {

            font-size: 20px;

            margin-right: 8px;

            color: #CFDACE;

        }



    .post-slide .read-more {

        padding: 7px 20px;

        float: right;

        font-size: 12px;

        background: #2196F3;

        color: #ffffff;

        box-shadow: 0px 10px 20px -10px #1376c5;

        border-radius: 25px;

        text-transform: uppercase;

    }



        .post-slide .read-more:hover {

            background: #3498db;

            text-decoration: none;

            color: #fff;

        }



.owl-controls .owl-buttons {

    text-align: center;

    margin-top: 20px;

}



    .owl-controls .owl-buttons .owl-prev {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 80px;

        padding: 0 18px 0 15px;

        border-radius: 50px;

        box-shadow: 3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



    .owl-controls .owl-buttons .owl-next {

        background: #fff;

        position: absolute;

        top: -13%;

        right: 15px;

        padding: 0 15px 0 18px;

        border-radius: 50px;

        box-shadow: -3px 14px 25px -10px #92b4d0;

        transition: background 0.5s ease 0s;

    }



        .owl-controls .owl-buttons .owl-prev:after,

        .owl-controls .owl-buttons .owl-next:after {

            content: "\f104";

            font-family: FontAwesome;

            color: #333;

            font-size: 30px;

        }



        .owl-controls .owl-buttons .owl-next:after {

            content: "\f105";

        }

h3.post-title {

    text-align: center;

}



.counter {

    height: 100%;

    background-image: linear-gradient( rgba(0,0,0,0.8), rgba(0,0,0,0.8) ), url(./images/counter-bg.jpg);

    background-size: cover;

    background-position: top;

    position: relative;

    padding-top: 5em;

    padding-bottom: 5em;

   

}



.page-heading {

    position: absolute;

    top: 40%;

    left: 50%;

    transform: translate(-50%, -50%);

    color: #aaa;

    white-space: nowrap;

}



.page-heading-primary {

    display: block;

    font-size: 3.125rem;

    font-weight: 300;

    color: #3498db;

}



.page-heading-secondary {

    display: block;

    font-size: 1.25rem;

    font-weight: 700;

    text-transform: uppercase;

    letter-spacing: 4.5px;

}

.number {

    width: 20%;

    float: left;

    margin: 20px;

    text-align: center;

    color: #f7a30a;

}

.clearfix {

    clear: both;

}

.count-text {
    color: #fff;
    font-weight: bold;
    margin-left: 25px;

}

.count {

    line-height: 100px;

    color: #f7a30a;

    margin-left: 30px;

    font-size: 4em;

}





/*Cookie CSS*/
.cookie h6 {
    font-size: 14px;
    font-weight: bold;
}

.cookie p {
    font-size: 13px;
}


@media only screen and (max-width:1280px) {

    .post-slide .post-content {

        padding: 0px 15px 25px 15px;

    }

}







@media (min-width: 1024px) {

    .container-fluid {

        padding-left: 30px;

        padding-right: 30px;

    }

    .news-grid .column-collapse {

        padding: 0;

        width: 25%;

        position: absolute;

        top: 0;

        right: 0;

    }

  

    .news-grid .large-image {

        padding-right: 25%;

        width: 100%;

        overflow: hidden;

        float: left;

    }

}



@media (max-width: 767px) {
    .faq_section button.accordion-button.collapsed {
 
        word-break: break-all; 
    }
.btn.btn-primary {
	
    margin-bottom: 10px;  font-size: 15px !important;
}
    .homepage-hero {
        margin-top: 0px;
    }
.desktop_view{ display:none;}


.mobile_view{ display:block; }
.player_name { 
    text-align: center; 
}
	.post-slide .post-img { 

    margin: -12px 15px 0px 15px; 

}
    .form-group, .EPiServerForms .Form__Element {
        margin-bottom: 15px;
    }
.search { 
    width: 100%;     top: 10px;
}
	.plus_icon {

    font-size: 1em !important;

}

    .main_head {

        margin-top: 10em;

    }

	.count {

    font-size: 2em;  line-height: 40px;

}

.hide_mobile{

	

	display:none;

}





    .header .dropbtn {

        padding: 10px 10px;

        font-size: 14px;

    }

	.article .article-panel {

    padding: 10px;

	height:100% !important;

}

    .header {

       margin-top: 20px;
    float: left;
    margin-bottom: 10px;

    }

    .count {

        margin-left: 0px;

    }

    .number {

        width: 49%;

        margin: 0px;

    }

    .logo {text-align:center;

    }

    .counter {

        margin-top: 0em;

        padding-top: 3em;

        padding-bottom: 3em;

    }

    .count-text {

        margin-left: 0px;

    }

    .site-header .part.second .last {

        margin-right: 0px;

        padding-top: 0px;

    }

    nav.navbar.navbar-expand-lg.navbar-light {

        padding: 5px 0px;

    }

}
.smallLogos .list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.smallLogos > div > div {
    font-size: 15px;
}
.border-bottom1 {
    border-bottom: 1px solid #000;
}

.widget-live-commentary {
    font-size: 16px;
    min-height: 200px;
    position: relative;
}
.coming-soon {
    display: none;
    margin-bottom: 40px;
}
    .coming-soon .panel {
        background-color: #fff;
        margin-bottom: 1px;
        padding: 40px;
        text-align: center;
    }
        .coming-soon .panel p.faint {
            opacity: .5;
        }

        .coming-soon .panel p {
            line-height: 1.55556em;
            margin: 0;
        }
.widget-live-commentary .control-header {
    background-color: #fff;
    border-bottom: 1px #f5f5f5 solid;
    padding: 20px 5px;
    position: relative;
}
    .widget-live-commentary .control-header .switch-checkbox {
        margin-top: 5px;
    }

    .widget-live-commentary .control-header .switch-checkbox, .widget-live-commentary .control-header p {
        color: #333;
        display: inline;
        float: right;
        font-size: 12px;
        margin-bottom: 0;
        margin-right: 20px;
        text-transform: uppercase;
    }

.switch-checkbox {
    background-color: gray;
    border-radius: 20px;
    cursor: pointer;
    height: 20px;
    overflow: hidden;
    width: 43px;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
    text-decoration: none;
    border-bottom: none !important;
    box-sizing: border-box;
}
    .switch-checkbox input {
        position: absolute;
        visibility: hidden;
    }
    .switch-checkbox label {
        background-color: #fff;
        border-radius: 35px;
        cursor: pointer;
        display: block;
        height: 16px;
        margin: 2px;
        margin-left: 2px;
        text-indent: -9999px;
        white-space: nowrap;
        width: 16px;
        -webkit-transition: margin-left .5s;
        transition: margin-left .5s;
    }
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.widget-live-commentary .control-header .switch-checkbox {
    margin-top: 5px;
}

.widget-live-commentary .control-header .switch-checkbox, .widget-live-commentary .control-header p {
    color: #333;
    display: inline;
    float: right;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 20px;
    text-transform: uppercase;
}


.widget-live-commentary .control-header p {
    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;
    font-weight: 800;
    margin-right: 10px;
    line-height: 26px;
    padding-top: 3px;
}

.widget-live-commentary .control-header .switch-checkbox, .widget-live-commentary .control-header p {
    color: #333;
    display: inline;
    float: right;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 20px;
    text-transform: uppercase;
}
    .widget-live-commentary .control-header p.last-updated {
        position: absolute;
        left: 20px;
        text-transform: none;
        font-family: "Gibbs-Book",Helvetica,Arial,sans-serif;
        font-weight: 400;
    }

.widget-live-commentary .control-header p {
    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;
    font-weight: 800;
    margin-right: 10px;
    line-height: 26px;
    padding-top: 3px;
}

.widget-live-commentary .control-header .switch-checkbox, .widget-live-commentary .control-header p {
    color: #333;
    display: inline;
    float: right;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 20px;
    text-transform: uppercase;
}
.widget-live-commentary .icon {
    margin-right: 10px;
}

[class^="link-icon-"]:before, [class*=" link-icon-"]:before, [class^="icon-"], [class*=" icon-"] {
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Opta {
    clear: both;
    width: 100%;
    padding: 0;
    border: none;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 100%;
}
    .Opta > .Opta_W {
        background: #fff;
        position: relative;
        width: 100%;
        margin: 10px 0;
        border: none;
        overflow: hidden;
        direction: inherit;
    }
.widget-live-commentary .Opta h2 {
    display: none;
}

.widget-live-commentary h2 {
    margin: 20px auto;
}

.Opta .Opta-H2, .Opta h2 {
    width: 100%;
    display: flex;
    align-items: center;
    height: 30px;
    background: #00aeef;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow: hidden;
    color: #fff;
}
.widget-live-commentary .Opta > .Opta_W > div {
    background: #f5f5f5;
    padding-bottom: 0;
}

.Opta > .Opta_W > div {
    position: relative;
    padding: 0 0 10px 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: #fff;
    color: #404040;
}
.Opta ul, .Opta ol {
    list-style: none;
}
.Opta div.Opta-Odd, .Opta li.Opta-Odd, .Opta .Opta-Odd th, .Opta .Opta-Odd td, .Opta .Opta-Striped li:nth-child(odd), .Opta .Opta-Striped tbody tr:nth-child(odd) th, .Opta .Opta-Striped tbody tr:nth-child(odd) td {
    background-color: #fff;
}

.widget-live-commentary .Opta li {
    font-size: 16px;
    border-left: 4px solid #fff;
    height: auto;
    opacity: 1;
    position: relative;
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 30px 10px;
    -webkit-transition: opacity .5s,height .25s,padding .25s,margin .25s;
    transition: opacity .5s,height .25s,padding .25s,margin .25s;
}

 

.Opta li {
    clear: both;
}
.widget-live-commentary .Opta li .Opta-Time {
    color: #333;
    font-size: 1.25em;
    line-height: 1.1em;
    height: auto;
    font-family: "Gibbs-Medium",Helvetica,Arial,sans-serif;
    font-weight: 800;
    margin-left: 6px;
    margin-right: 40px;
}

.widget-live-commentary .Opta li span {
    -webkit-transition: opacity .5s,height .25s,padding .25s,margin .25s;
    transition: opacity .5s,height .25s,padding .25s,margin .25s;
}

.Opta_F_C .Opta-Time {
    float: left;
    width: 20px;
    margin: 0 10px 0 0;
    color: gray;
}
.Opta-Icon {
    background-image: url('./images/all-events-test.svg') !important;
    background-size: 35px 270px;
}
.widget-live-commentary .Opta li .Opta-Event {
    margin: 0 0 0 8px;
}

.Opta_F_C .Opta-Events span.Opta-Event {
    float: left;
    margin: 0 14px;
}

.Opta .Opta-Events span.Opta-Event {
    display: inline;
    margin: 6px 6px 0;
    height: 18px;
    overflow: hidden;
}

.Opta .Opta-Events span.Opta-Event {
    display: inline;
    margin: 6px 6px 0;
    height: 18px;
    overflow: hidden;
}

.Opta .Opta-Icon.Opta-IconSubstitution {
    background-position: -12px -10px;
    width: 11px;
}
.Opta .Opta-Events span.Opta-Event {
    display: inline;
    margin: 6px 6px 0;
    height: 18px;
    overflow: hidden;
}

.Opta .Opta-Events span.Opta-Event {
    display: inline;
    margin: 6px 6px 0;
    height: 18px;
    overflow: hidden;
}

.Opta .Opta-Icon.Opta-IconWhistle {
    background-position: -10px -250px;
    width: 16px;
}
ul.Opta-Striped {
    margin: 0px;
    padding: 0px;
}
.widget-live-commentary .Opta li span {
    -webkit-transition: opacity .5s,height .25s,padding .25s,margin .25s;
    transition: opacity .5s,height .25s,padding .25s,margin .25s;
}
.switch-checkbox label {
    background-color: #fff;
    border-radius: 35px;
    cursor: pointer;
    display: block;
    height: 16px;
    margin: 2px;
    margin-left: 2px;
    text-indent: -9999px;
    white-space: nowrap;
    width: 16px;
    -webkit-transition: margin-left .5s;
    transition: margin-left .5s;
}
.Opta_F_C .Opta-comment-VAR {
    display: block;
    float: right;
    height: 30px;
    width: 30px;
}
.widget-live-commentary .Opta li.special-event .Opta-comment {
    margin: 0 10px 0 124px;
}

.widget-live-commentary .Opta li .Opta-comment {
    color: #333;
    font-family: "Gibbs-Book",Helvetica,Arial,sans-serif;
    font-size: 1em;
    line-height: 1.5em;
    margin: 0 10px 0 124px;
}

.widget-live-commentary .Opta li span {
    -webkit-transition: opacity .5s,height .25s,padding .25s,margin .25s;
    transition: opacity .5s,height .25s,padding .25s,margin .25s;
}

.Opta_F_C .Opta-comment {
    display: block;
    margin: 0 40px 0 72px;
    font-size: 12px;
}
.widget-live-commentary .Opta li span {
    -webkit-transition: opacity .5s,height .25s,padding .25s,margin .25s;
    transition: opacity .5s,height .25s,padding .25s,margin .25s;
}

.Opta .Opta-Emphasis, .Opta .Opta-Emphasis a {
    font-weight: bold;
}





.check {
    display: flex;
    gap: 1rem;
}

.check__item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 1rem;
}

    .check__item label {
        display: flex;
        align-items: center;
        -moz-column-gap: 6px;
        column-gap: 6px;
    }

.default__check[type=checkbox],
.default__check[type=radio] {
    display: none;
}

    .default__check[type=checkbox] ~ .custom__check,
    .default__check[type=radio] ~ .custom__check {
        display: flex;
        align-items: center;
        height: 16px;
        background: grey;
        margin-top: 8px;
        position: relative;
        transition: all 0.4s ease;
        cursor: pointer;
    }

        .default__check[type=checkbox] ~ .custom__check:after,
        .default__check[type=radio] ~ .custom__check:after {
            content: "";
            display: inline-block;
            position: absolute;
            transition: all 0.4s ease;
        }

    .default__check[type=checkbox]:not(.switchbox) ~ .custom__check,
    .default__check[type=radio]:not(.switchbox) ~ .custom__check {
        justify-content: center;
        width: 16px;
    }

        .default__check[type=checkbox]:not(.switchbox) ~ .custom__check:after,
        .default__check[type=radio]:not(.switchbox) ~ .custom__check:after {
            visibility: hidden;
        }

    .default__check[type=checkbox].switchbox + .custom__check,
    .default__check[type=radio].switchbox + .custom__check {
        width: 32px;
    }

        .default__check[type=checkbox].switchbox + .custom__check:after,
        .default__check[type=radio].switchbox + .custom__check:after {
            transform: scale(1.5);
            left: 4px;
        }

    .default__check[type=checkbox].switchbox:not(:checked) ~ .custom__check:after,
    .default__check[type=radio].switchbox:not(:checked) ~ .custom__check:after {
        background-color: #fff;
    }

    .default__check[type=checkbox].switchbox:checked ~ .custom__check:after,
    .default__check[type=radio].switchbox:checked ~ .custom__check:after {
        left: 20px;
    }

    .default__check[type=checkbox]:disabled ~ .custom__check,
    .default__check[type=radio]:disabled ~ .custom__check {
        opacity: 0.3;
        cursor: not-allowed;
    }

    .default__check[type=checkbox]:checked ~ .custom__check,
    .default__check[type=radio]:checked ~ .custom__check {
        background-color: #48a326;
    }

        .default__check[type=checkbox]:checked ~ .custom__check:after,
        .default__check[type=radio]:checked ~ .custom__check:after {
            visibility: visible;
        }

    .default__check[type=checkbox]:not(.switchbox) ~ .custom__check {
        border-radius: 0.375rem;
    }

        .default__check[type=checkbox]:not(.switchbox) ~ .custom__check:after {
            height: 7px;
            width: 3px;
            margin-top: -1px;
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
        }

    .default__check[type=radio] ~ .custom__check,
    .default__check[type=checkbox].switchbox ~ .custom__check {
        border-radius: 50rem;
    }

        .default__check[type=radio] ~ .custom__check:after,
        .default__check[type=checkbox].switchbox ~ .custom__check:after {
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50rem;
        }

.ProductTile .Price {
 
    margin-bottom: 20px;
}
.name-section {
     margin-top:5rem;
}
.icn-heart {
    border: 1px solid #c5c6c9;
    border-radius: 3px;
    float: left;
    padding: 6px 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.collect-count {
    font-size: 14px;
    color: #222325;
    padding: 2px 10px;
    margin-left: 13px;
    position: relative;
    margin-right: 20px;
    float: left;
    border: 1px solid #dadbdd;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.share-btn {
    border: 1px solid #c5c6c9;
    border-radius: 3px;
    float: left;
    padding: 6px 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.share-icon {
    font-size: 30px !important;
    float: left;
    margin-right: 10px;
}
    .collect-count:before {
        z-index: 2;
        border-width: 8px 9px 8px 0;
        border-color: transparent #dadbdd transparent transparent;
        top: 6px;
        left: -9px;
    }

.collect-count:after {
    content: "";
    position: absolute;
    border-style: solid;
}

.collect-count:after {
    z-index: 3;
    border-width: 6px 8px 6px 0;
    border-color: transparent #dedede transparent transparent;
    top: 8px;
    left: -8px;
}
.share-icon2 {
    font-size: 24px !important;
    float: right;
    margin-right: 10px;
    margin-left: 10px;
}
 .collect-count:after {
    content: "";
    position: absolute;
    border-style: solid;
}
@media only screen and (min-width: 768px) {
    .smallLogos .list {
        gap: 2%;
    }
        .smallLogos .list > div {
            width: 12.57%;
            flex-grow: 1;
        }
}

@media only screen and (max-width: 767px) {
    .smallLogos .list > div {
        width: 21.5%;
        flex-grow: 1;
    }
    .name-section {
        margin-top: 7rem;
    }
    .InstantSearch__filters {
  
        width: 100% !important;
    }
    .featurecontainer {
       flex-direction: column;
       align-items: center;
    }
}

.small-features { 
    background: #fff;
    display: flex;
    z-index: 1;
    padding: 80px 0;
    box-shadow: 0 0 13px rgb(175 177 193 / 15%), 0 6px 20px rgb(24 29 39 / 5%);
}

.featurecontainer {
    position: relative;
    width: 1020px;
    margin: 0 auto;
    display: flex;
}

.feature {
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.text-center { 
    text-align: center!important;
}

.text-center-f { 
    text-align: center!important;
    font-size: 2.5rem;
}


@media only screen and (max-width: 990px) {
    .text-center { 
        text-align: center!important;
        font-size: 1.8rem;
        flex-direction: column;
    }
}

.dis { 
    display: none !important;
}

@media only screen and (max-width: 990px) {
    .mid { 
        display: none;
    } 
    .dis { 
        display: inline-block !important;
        margin-left: 0px !important;
    }
}




h1 {
    font-family: 'Montserrat', sans-serif !important;
  }

h2 {
    font-family: 'Montserrat', sans-serif !important;
  }

p { 
    font-family: 'Karla', sans-serif !important;
}

.Typewriter { 
    display: inline-block;
}
.react-dataTable {
    padding-left: 100px;
    padding-right: 100px;
  }

.google{
    margin-top: 31px;
    color: whitesmoke;
    background-color: #000;
    
}